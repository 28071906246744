import React, { Fragment } from 'react';
import '@royalnavy/css-framework';   


function About() {
   return(
     <Fragment>
      <h1>About</h1>
      
<p>Beta BR 320 - The Naval Catalogue (NavCat) / Project Nelson: NSN-Search</p>

<p>Version 3.0.0-b1.  This is a beta version intended for development and test only.</p>

		 <h2>Background</h2>
<p>BR 320 NavCat is a searchable database of NATO Stock Numbers. It is designed for non-Supply Chain personnel to quickly find authoratative information so allowing accurate stores demands to be placed.  Previously it was the responsibility of DE&amp;S SCIS to distribute by CD (which replaced a microfiche version), however responsibility has now passed to NCHQ with this online version which is intended to be hosted on the NELSON platform.</p>

      <p>BR 320 does not replace MJDI or UK NCB's CSIS, but is rather intended as a quick reference tool for ship's staff to use onboard.</p>

		 <h2>Contact</h2>
<p>Feedback and comments are welcomed at <a href="mailto:5tgypstck6@liamekaens.com">5tgypstck6@liamekaens.com</a>.</p>
     </Fragment>
   );
}


export default About;

