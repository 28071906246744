import { gql } from 'apollo-boost';

const GET_SUPERSESSIONS = gql`
query Supersession($kenc: Int!, $keiin: Int!){
  alts(where: {csupress_eq: 2, and: {knc_eq: $kenc, kiin_eq: $keiin}}) {
  id
  knc  
  kiin
  lnc
  liin
  }
}
`;

export default GET_SUPERSESSIONS;

