import React, { Fragment } from 'react';
import '@royalnavy/css-framework';   


function Faq() {
   //TODO: Correct non-HTML5 compliant css/html
   return(
     <Fragment>
      <h1>Frequently Asked Questions</h1>
      <h2>What is the difference between an IIN, NIIN and NSN?</h2>
      <h3>In summary:</h3>
      

         <table style={{width:300}} className='rn-table' align="center">
            <tr>
              <td align="center"><font color="Red">1005</font></td>
              <td align="center"><font color="Red">13</font></td>
              <td align="center"><font color="Red">1234567</font></td>
            </tr>
            <tr>
              <td align="center">NATO Supply<br />
                Classification<br />
                Code (NSC)</td>
              <td align="CENTER">Nation Code (NC)</td>
              <td align="CENTER">Item Identification Number (IIN)</td>
            </tr>
            <tr>
              <td rowspan="2">&nbsp;</td>
              <td align="center" colspan="2"><font color="Red">13-1234567</font></td>
            </tr>
            <tr>
              <td align="center" colspan="2">NATO Item Identification <br />Number
				(NIIN)</td>
            </tr>
            <tr>
              <td align="CENTER" colspan="3"><font color="Red">1005-13-1234567</font></td>
            </tr>
            <tr>
              <td align="CENTER" colspan="3">NATO Stock Number<br />
				(NSN)</td>
            </tr>
          </table>
      <h3>In depth:</h3>
      <dl>
      <dt>IIN</dt>
      <dd><strong>Item Identification Number</strong>.  A 7-digit number that uniquely identifies an item as issued by one nation.  For example the IIN for a "Plug, bolt" is 9998808 as codified in the USA, while the same IIN is used to codify "Insulation Sleeving, Electrical" in the UK.</dd>
      <dt>NIIN</dt>
      <dd><strong>NATO Item Identifcation Number</strong>. A 9-digit number formed by an IIN prefixed with a two digit Nation Code which represents the codifying nation.  Examples of Nation Codes are 99 for the UK and 00 for the US.  Using the above example, NIIN 00-9998808 would identify the US "Plug, bolt", while 99-9998808 would identify the UK "Insulation Sleeving, Electrical". </dd>
      <dt>NSN</dt>
      <dd><strong>NATO Stock Number</strong>.  A 13-digit number formed by a NIIN prefixed by a NATO Supply Classification Code (NSC).  The NSC is used to group items on the basis of functional or performance charateristics.  The NSN for "Insulation Sleeving, Electrical" is 5970-99-9998808 with the NSC of 5970 denoting Electrical and Electronic Equipment Components: Electrical Insulators and Insulating Materials.  Items are on occasion recategorised by NATO, so the NIIN is typically preferred to a NSN as it is less likely to change.</dd>
      </dl>
      <h2>What is a DMC?</h2>
<p>A Domestic Management Code (DMC), also known as an Inventory Management Code, identifies the Commodity Manager responsible for procuring an item.</p>  
     </Fragment>
   );
}


export default Faq;

