import { gql } from 'apollo-boost';

const GET_ITEM_RECORD = gql`
  query ItemRecord($nc: Int!, $iin: Int){
    items(where: {nc_eq: $nc, iin_eq: $iin} ) {
      id
      nsc
      nc
      iin
      sin
      crisppartno
      desc
      commoditymgr {
        dmc
      }
    }
  }
`;

export default GET_ITEM_RECORD;
