import React, { Fragment } from 'react';
import SearchBack from 'Components/SearchBack';
import SuperResultTable from 'Components/SuperResultTable';

function SuperResult(props) {
   let {knc} = props.match.params;
   let {kiin} = props.match.params;
   // TODO: This should be a string, but FakeQL insists on int
   knc = parseInt(knc); 
   // TODO: Therfore remove when on NELSON
   kiin = parseInt(kiin);    
   return (
     <Fragment>
      <h1>Supersessions search results</h1>
      <SuperResultTable knc={knc} kiin={kiin} />
      <SearchBack returnpath="/supersessions" />
     </Fragment>
   )
}


export default SuperResult;

