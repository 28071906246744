import React, { Fragment } from 'react';
import { useQuery } from '@apollo/react-hooks';
import GET_CM_RECORD from 'Queries/GET_CM_RECORD';
import SearchBack from 'Components/SearchBack';
import '@royalnavy/css-framework';   

function CMRecord(props) {
	let {dmc} = props.match.params;

      const { loading, error, data } = useQuery(GET_CM_RECORD, {variables: {dmc: dmc}});
      if (loading) return <p>Loading...</p>;
      if (error) return <p>Error</p>;
      if (data.commoditymgrs.length===0) return <Fragment><h1>Commodity Manager record</h1><p>No data held for {dmc}</p></Fragment>;
   
   console.log("data", data);
   console.log(data.commoditymgrs[0].dmc)
   console.table(data.commoditymgrs);
   
      //TODO: Should below be a map, as only one record should be returned
      //If duplicate is present then there is something wrong
      //User should be informed.
   return(
     <Fragment>
      <h1>Commodity Manager record</h1>
         {data.commoditymgrs.map(commoditymgrs => ( 
 <div className="rn-card" key={commoditymgrs.id}>
    <div className="rn-card__header">DMC {dmc}</div>
    <div className="rn-card__body">
            <p>DMC: {commoditymgrs.dmc}</p>
            <p id="pt">PT: {commoditymgrs.pt}</p>
            <p>Email: {commoditymgrs.email}</p>
            <p>Room: {commoditymgrs.room}</p>
            <p>Tel: {commoditymgrs.tel}</p>
            <p>Description: {commoditymgrs.desc}</p>
</div>
</div>
      ))}
      
      <SearchBack returnpath="/cmlookup"/>
      </Fragment>
   )
}

export default CMRecord;

/*
 * JDH - Below removed as assumes user arrived via Item Search rather than Alternatives, Superessession or direct URL.  User can instead simply use top nav bar to navigate...
      <form action="/itemsearch">
      <p><Button type="submit" variant="primary">Search again</Button></p>
      </form>
 *
 */
