import React, { Fragment } from 'react';
import SearchBack from 'Components/SearchBack';
import AltResultTable from 'Components/AltResultTable';

function AltResult(props) {
   let {knc} = props.match.params
   let {kiin} = props.match.params
   knc = parseInt(knc) // TODO: This should be a string, but FakeQL insists on int
   kiin = parseInt(kiin) // TODO: Therfore remove when on NELSON
   return(
     <Fragment>
      <h1>Alternatives search results</h1>
      <strong>Alternative items for <var>{props.location.state.search}</var>:</strong>
      <AltResultTable knc={knc} kiin={kiin}/>
      <SearchBack returnpath="/alternatives" />
     </Fragment>
   )
}


export default AltResult;

