import React, { Fragment } from 'react';
import { Formik, Form, Field } from "formik";
import { Redirect } from 'react-router-dom';
import { Formik as FormControls, Icons, Button, Badge } from "@royalnavy/react-component-library";
import "@royalnavy/css-framework";
import * as Yup from 'yup';

const { TextInput } = FormControls;
const { Search } = Icons;


const dmcSchema = Yup.object().shape({
  dmc: Yup.string()
    .required(<span> <Badge color="warning">Error</Badge> - Value required</span>),
});


class CMLookup extends React.Component {
   // State indicates whether user submitted search turn,
   // used in redirect
   state = {
      searchSubmitted: false,
      searchValue: ''
   }
   render() {
      if (this.state.searchSubmitted) { 
         const dmc = this.state.searchValue;
         return <Redirect push to={{
            pathname: `/cmrecord/${dmc}`,
               state: { searchValue: this.state.searchValue, 
                     search: this.state.searchValue}
            //TODO: Both of these values are not required.  
            //Choose one and delete other.
            //I prefer 'search' as this is an integral part of Redirect
            //and has a name on tin element about it!
					 //TODO: Or can I delete both as actually passed in URL not as state?
         }}/>;
      } else { 
         return(
            <Fragment>
            <h1>Commodity Manager lookup</h1>

            <p>Lookup details of a commodity manager using a Domestic Management Code (e.g. A809):</p>  


            <Formik 
            initialValues={{ dmc: '' }}
            onSubmit = {values => {
               this.setState({searchSubmitted: true, searchValue: values.dmc})
            }
            }
            validationSchema={dmcSchema}
            >
            {() => (
               <Form>
               <Field 
               className="rn-textinput--is-valid"
               name="dmc"
               component={TextInput}
               label="DMC"
               startAdornment={<Search />} />
               <p><Button type="submit" variant="primary">Submit</Button></p>
               </Form>
            )}

            </Formik>
            </Fragment>
         );
      }
   }
}


export default CMLookup;

