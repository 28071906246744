import React from 'react';
import { Switch, Route } from 'react-router-dom';
import '@royalnavy/css-framework';   
import GettingStarted from 'Pages/GettingStarted';
import ItemSearch from 'Pages/ItemSearch.js';
import ItemResult from 'Pages/ItemResult';
import ItemRecord from 'Pages/ItemRecord';
import CMLookup from 'Pages/CMLookup';
import CMRecord from 'Pages/CMRecord';
import Alternatives from 'Pages/Alternatives';
import AltResult from 'Pages/AltResult';
import Supersessions from 'Pages/Supersessions';
import SuperResult from 'Pages/SuperResult';
import About from 'Pages/About';
import Faq from 'Pages/Faq';

class Main extends React.Component {
   render() {
      return (
    <main className="rn-container">
      <Switch>
        <Route exact path='/' component={GettingStarted}/>
        <Route exact path='/itemsearch' component={ItemSearch}/>
        <Route path='/itemresult' component={ItemResult}/>
        <Route path='/itemrecord/:nc/:iin' component={ItemRecord}/>
        <Route path='/cmlookup' component={CMLookup}/>
        <Route path='/cmrecord/:dmc' component={CMRecord}/>
        <Route path='/alternatives' component={Alternatives}/>
        <Route path='/altresult/:knc/:kiin' component={AltResult}/>
        <Route path='/supersessions' component={Supersessions}/>
        <Route path='/superresult/:knc/:kiin' component={SuperResult}/>
        <Route path='/faq' component={Faq}/>
        <Route path='/about' component={About}/>
      </Switch>
    </main>
      )
  };
}

export default Main;
