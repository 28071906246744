import { gql } from 'apollo-boost';


const GET_ITEMS_SUMMARY_ADV = gql`
  query GISA($mpn: String, $sin: String, $desc: String, $nc: Int, $iin: Int){
    items(where: {crisppartno_contains: $mpn,  and: {sin_contains: $sin, and: {desc_contains: $desc, and: {nc_eq:$nc, and: {iin_eq:$iin}}}}}){
    id
    nsc
    nc
    iin
    sin
    crisppartno
    desc
    commoditymgr {
      dmc
    }
  }
}     
`;

export default GET_ITEMS_SUMMARY_ADV;
