import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import GET_SUPERSESSIONS from 'Queries/GET_SUPERSESSIONS';


function SuperResultTable(props) {
   let knc = props.knc;
   let kiin = props.kiin;
   console.log("AGQ knc", knc);
   console.log("AGQ kiin", kiin);
   console.log("AGQpostparse knc", knc);
   console.log("AGQpostparse kiin", kiin);
   const { loading, error, data } = useQuery(GET_SUPERSESSIONS, {variables: {keiin: kiin, kenc: knc}});
   if (loading) return <p>Loading...</p>;
   if (error) return <p>Error</p>;
   //TODO: Require validation here to ensure searched for NIIN is valid.
   if (data.alts.length===0) return <p><strong>No record for <var>{knc}{kiin}</var>.</strong></p>;
   console.log('returned data', data);
   //console.log('DMC', data.items["0"].commoditymgr.dmc,);
   return (
      <fragment>
      <p><strong>NIIN <var>{knc}{kiin}</var> has been superseded by:</strong></p>
      <table className='rn-table'>
      <thead>
      <tr>
      <th><abbr title='NATO Item Identication Number'>NIIN</abbr></th>
      </tr>
      </thead>
      <tbody>
      {data.alts.map(alts => 
         <tr key={alts.id}>
         <td><Link to={`/itemrecord/${alts.lnc}/${alts.liin}`}>{alts.lnc}{alts.liin}</Link></td>
         </tr>
      )}
      </tbody>
      </table>
      </fragment>
   );
}

export default SuperResultTable;

