import { gql } from 'apollo-boost';


const GET_ITEM_SUMMARY = gql`
  query QS($quicksearch: String!){
    items(where: {
    or: [
    {crisppartno_contains: $quicksearch},
    {sin_contains: $quicksearch}
    ]}
    ) {
    id
    nsc
    nc
    iin
    sin
    crisppartno
    commoditymgr {
      dmc
    }
  }
}     
`;

export default GET_ITEM_SUMMARY;
