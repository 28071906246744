import { gql } from 'apollo-boost';

const GET_CM_RECORD = gql`
  query CMRecord($dmc: String!){
    commoditymgrs(where: {dmc_eq: $dmc}) {
      id
      dmc
      pt  
      email
      room
      tel
      desc
      }
   }
`;

export default GET_CM_RECORD;
