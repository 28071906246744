import React, { Fragment } from 'react';
import { useQuery } from '@apollo/react-hooks';
import GET_ITEM_RECORD from 'Queries/GET_ITEM_RECORD';
import ResultsBack from '../Components/ResultsBack';
import '@royalnavy/css-framework';   

function ItemRecord(props) {
   let {nc} = props.match.params;
   let {iin} = props.match.params;
   nc = parseInt(nc); // TODO: This should be a string, but FakeQL insists on int
   iin = parseInt(iin); // TODO: Therfore remove when on NELSON

      const { loading, error, data } = useQuery(GET_ITEM_RECORD, {variables: {nc: nc, iin: iin}});
      if (loading) return <p>Loading...</p>;
      if (error) return <p>Error</p>;
      if (data.items.length===0) return <p>No data!</p>;
   
   console.log("data", data);
   console.log(data.items[0].dmc)
   console.table(data.items);
   
   return(
     <Fragment>
      <h1>Item Record</h1>
         {data.items.map(items => (
 <div className="rn-card" key={items.id}>
    <div className="rn-card__header">Item {items.nc}{items.iin}</div>
    <div className="rn-card__body">
            <p>DMC: {items.commoditymgr.dmc}</p>
            <p>NSC: {items.nsc}</p>
            <p>NC: {items.nc}</p>
            <p>IIN: {items.iin}</p>
            <p>SIN: {items.sin}</p>
            <p>Description: {items.desc}</p>
            <p>MPN: {items.crisppartno}</p>
</div>
</div>
      ))}
      
      <ResultsBack />
      </Fragment>
   )
}

export default ItemRecord;

/*
 * JDH - Below removed as assumes user arrived via Item Search rather than Alternatives, Superessession or direct URL.  User can instead simply use top nav bar to navigate...
      <form action="/itemsearch">
      <p><Button type="submit" variant="primary">Search again</Button></p>
      </form>
 *
 */
