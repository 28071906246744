import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { Formik, Form, Field } from "formik";
import '@royalnavy/css-framework';
import { Formik as FormControls, Icons, Button, Badge } from "@royalnavy/react-component-library";
import "@royalnavy/css-framework";
import * as Yup from 'yup';

const { TextInput } = FormControls;
const { Search } = Icons;

const quickSchema = Yup.object().shape({
  name: Yup.string()
    .required(<span> <Badge color="warning">Error</Badge> - Value required</span>),
});

const advSchema = Yup.object().shape({
  niin: Yup.string()
    .length(9, <span> <Badge color="warning">Error</Badge> - NIIN must be 9 digits</span>)
});


class ItemSearch extends React.Component {
   // State indicates whether user submitted search turn,
   // used in redirect
   state = {
      searchSubmitted: false,
      searchValue: '',
      quickValue: '', //User input for Quick Search
      quickSearchType: true, // True for Quick, false for Advanced Search
      advMPN: '',
      advNIIN: '',
      advSIN: '',
      advDesc: ''
   }


   render() {
      if (this.state.searchSubmitted) { 
         return <Redirect push to={{
            pathname: "/itemresult",
               state: { searchValue: this.state.searchValue, 
                     search: this.state.searchValue,
                     quickSearchType: this.state.quickSearchType, 
                     advMPN: this.state.advMPN,
                     advNIIN: this.state.advNIIN,
                     advSIN: this.state.advSIN,
                     advDesc: this.state.advDesc
               }
            //TODO: Both of these values are not required.  
            //Choose one and delete other.
            //I prefer 'search' as this is an integral part of Redirect
            //and has a name on tin element about it!
         }}/>;
      } else { 
         return(
            <Fragment>
            <h1>Item Search</h1>
            <h2>Quick Search</h2>
<p>Search for items using Part Number, <del>NIIN</del> or Item Name.  Entering part of a search pattern will search all items containing that pattern, e.g. entering "M" will search for all Part Numbers and Item Names containing "M".</p>

            <Formik 
            initialValues={{ name: '' }}
            onSubmit = {values => {
               this.setState({searchSubmitted: true, searchValue: values.name})
            }
            }
            validationSchema={quickSchema}
            >
            {() => (
               <Form>
               <Field 
               className="rn-textinput--is-valid"
               name="name" 
               component={TextInput}
               label="Part No., Item Name or [DISABLED] NIIN"
               startAdornment={<Search />} />
               <p><Button type="submit" variant="primary">Submit</Button></p>
               </Form>
            )}

            </Formik>
            <h2>Advanced Search</h2>
            <p>Search for items which match all of the following fields, e.g. searching for Part No "M" and Item Name "RING" would show all items where Item Name contained "RING" and Part No contained "M":</p>
            
            <Formik 
            initialValues={{ mpn: '', niin: '', sin: '', desc: ''   }}
            onSubmit = {values => {
               this.setState({searchSubmitted: true, 
                  quickSearchType: false, // True for Quick, false for Advanced Search
                  advMPN: values.mpn,
                  advNIIN: values.niin,
                  advSIN: values.sin,
                  advDesc: values.desc
               })
            }
            }
            validationSchema={advSchema}
            >
            {() => (
               <Form>
               <Field 
               className="rn-textinput--is-valid"
               name="mpn" 
               component={TextInput}
               label="Manafacturer's Part Number"
               startAdornment={<Search />} />
               <Field 
               disabled="true"
               className="rn-textinput--is-valid"
               name="niin" 
               component={TextInput}
               label="[DISABLED] NATO Item Identification Number (NIIN)"
               startAdornment={<Search />} />
               <Field 
               className="rn-textinput--is-valid"
               name="sin" 
               component={TextInput}
               label="Item Name"
               startAdornment={<Search />} />
               <Field 
               className="rn-textinput--is-valid"
               name="desc" 
               component={TextInput}
               label="Description"
               startAdornment={<Search />} />
               <p><Button type="submit" variant="primary">Submit</Button></p>
               </Form>
            )}

            </Formik>
            </Fragment>
         );
      }
   }
}


export default ItemSearch;
