import { gql } from 'apollo-boost';

const GET_ALTS = gql`
query AltKey ($keiin: Int!, $kenc: Int!){
  alts(where: {kiin_eq: $keiin, and: {knc_eq: $kenc}}) {
  id
  kiin
  lnc  
  liin
  keylis
  }
}
`;

export default GET_ALTS;

