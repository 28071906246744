import React from 'react';
import '@royalnavy/css-framework';   
import { Masthead, PhaseBanner } from '@royalnavy/react-component-library';
//import NavcatMasthead from './Components/NavcatMasthead';
import Main from 'Pages/Main';
import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient from 'apollo-boost';
import { withRouter  } from 'react-router-dom';
import PropTypes from 'prop-types';
const client = new ApolloClient({
  uri: 'https://fakeql.com/graphql/c70332fb20c62df54e43f7ad6558bc0e',
});

function determineRoute(nav, route) {
   if (nav === route) {
      return true;
   } else {
      return false;
   }
};

function Graphic() {
  return (
      <img src="navcat_logo.png" height="55" width="154" alt=""/>
  )}


class App extends React.Component {
  

   // To keep track of location for routing
   static propTypes = {
    location: PropTypes.object.isRequired,
  }
   
   render() {
    const { location } = this.props
      return (
  <ApolloProvider client={client}>
				<PhaseBanner phase="beta">This service is for test purposes only - all datasets shown are mock.</PhaseBanner>
    <Masthead   
     homeLink={{href:'/'}}  
     navItems={[    
        { label: 'Get started', href: '/', active:determineRoute('/', location.pathname)},    
        { label: 'Item search', href: '/itemsearch', active:determineRoute('/itemsearch', location.pathname)},
        { label: 'CM lookup', href: '/cmlookup', active:determineRoute('/cmlookup', location.pathname)},
        { label: 'Alternatives', href: '/alternatives', active:determineRoute('/alternatives', location.pathname)},
        { label: 'Supersessions', href: '/supersessions', active:determineRoute('/supersessions', location.pathname)},
        { label: 'FAQ', href: '/faq', active:determineRoute('/faq', location.pathname)},
        { label: 'About', href: '/about', active:determineRoute('/about', location.pathname)},
     ]}  
     title="NATO Stock Number Search"   
     Logo={Graphic}
     />
     <Main />
     </ApolloProvider>
  );
   }
}


export default withRouter(App);

