import React, { Fragment } from 'react';
import ItemResultTable from 'Components/ItemResultTable';
import SearchBack from 'Components/SearchBack';



function ItemResult(props) {
   //console.log(props);

   const advMPN = {
      textName: 'MPN',
      textProp: props.location.state.advMPN,
   };

   const advSIN = {
      textName: 'Short Item Name',
      textProp: props.location.state.advSIN,
   };

   const advDesc = {
      textName: 'Description',
      textProp: props.location.state.advDesc,
   };

   function SearchedForText(field) {
      if (field.textProp !== '') {
         return <li>{field.textName}: <var>{field.textProp}</var></li>
      }
   };

   function SearchedFor() {
      if (props.location.state.quickSearchType) {
         return <fragment>
            Searched for: <var>{props.location.state.search}</var>
            </fragment>;
      } else {
         return <fragment> 
            Advanced searched for:
            <ul>{SearchedForText(advMPN)}
         {SearchedForText(advSIN)}
         {SearchedForText(advDesc)}</ul>
            </fragment>;
      }
   };

   return(
      <Fragment>
      <h1>Item search results</h1>
      <SearchedFor />
      <ItemResultTable 
      quickSearchType={props.location.state.quickSearchType} 
      quickSearch ={props.location.state.search} 
      advMPN={props.location.state.advMPN}
      advNIIN={props.location.state.advNIIN}
      advSIN={props.location.state.advSIN}
      advDesc={props.location.state.advDesc} />
      <SearchBack returnpath="/itemsearch"/>
      </Fragment>
   )
}

export default ItemResult;

