import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import '@royalnavy/css-framework';   


function GettingStarted() {
   return(
     <Fragment>
     <p>BR 320 NavCat is a searchable database of NATO Stock Numbers. It is designed for non-Supply Chain personnel to quickly find authoratative information so allowing accurate stores demands to be placed.</p>
     <ul>
     <li><p><Link to='itemsearch'>Find item details (e.g. <abbr title="Project Team">PT</abbr> or <abbr title="NATO Stock Number">NSN</abbr>) from a part number or partial description</Link></p></li>
     <li><p><Link to='cmlookup'>Lookup contact details for a <abbr title="Defence Equipment & Support">DE&S</abbr> Commodity Manager</Link></p></li>
     <li><p><Link to='alternatives'>Find alternatives to an item</Link></p></li>
     <li><p><Link to='supersessions'>Find items that have replaced a superseded (e.g. obsolete) item</Link></p></li>
     </ul>
     </Fragment>
   );
}


export default GettingStarted;

