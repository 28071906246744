import React, { Fragment } from 'react';
import { Formik, Form, Field } from "formik";
import { Redirect } from 'react-router-dom';
import { Formik as FormControls, Icons, Button, Badge } from "@royalnavy/react-component-library";
import "@royalnavy/css-framework";
import * as Yup from 'yup';

const { TextInput } = FormControls;
const { Search } = Icons;

function findNC(niin) {
   return niin.substring(0,2);
}

function findIIN(niin) {
   return niin.substring(2);
}

const niinSchema = Yup.object().shape({
  niin: Yup.string()
    .length(9, <span> <Badge color="warning">Error</Badge> - NIIN must be 9 digits</span>)
    .required(<span> <Badge color="warning">Error</Badge> - Value required</span>),
});


class Supersessions extends React.Component {
   // State indicates whether user submitted search turn,
   // used in redirect
   state = {
      searchSubmitted: false,
      searchValue: ''
   }
   render() {
      if (this.state.searchSubmitted) { 
         const knc = findNC(this.state.searchValue);
         const kiin = findIIN(this.state.searchValue);
         return <Redirect push to={{
            pathname: `/superresult/${knc}/${kiin}`,
               state: { searchValue: this.state.searchValue, 
                     search: this.state.searchValue}
            //TODO: Both of these values are not required.  
            //Choose one and delete other.
            //I prefer 'search' as this is an integral part of Redirect
            //and has a name on tin element about it!
         }}/>;
      } else { 
         return(
            <Fragment>
            <h1>Supersessions</h1>

            <p>Supersessions occur when items reach obsolescence and are replaced.</p>  <p>Enter a NIIN (e.g. 999999422) to determine whether an item has been superseded on CRISP:</p>


            <Formik 
            initialValues={{ niin: '' }}
            onSubmit = {values => {
               this.setState({searchSubmitted: true, searchValue: values.niin})
            }
            }
            validationSchema={niinSchema}
            >
            {() => (
               <Form>
               <Field 
               className="rn-textinput--is-valid"
               name="niin"
               component={TextInput}
               label="NIIN"
               startAdornment={<Search />} />
               <p><Button type="submit" variant="primary">Submit</Button></p>
               </Form>
            )}

            </Formik>
            </Fragment>
         );
      }
   }
}

export default Supersessions;

