import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import GET_ALTS from 'Queries/GET_ALTS';

function humanreadARC(arc) {
   let msg;
   switch (arc) {
      case 0:
         msg = 'PT approval not required';
         break;
      case 1:
         msg = 'PT approval required';
         break;
      case 2:
         msg = 'Not an alternative (TODO: Check if valid response)';
         break;
      case 9:
         msg = 'TODO: Define error code 9';
         break;
      default:
         msg = 'Error (ALT-1). Please see FAQ to report.';
         break;
   }
   return msg;
}

function AltResultTable(props) {
   let knc = props.knc;
   let kiin = props.kiin;
   console.log("AGQ knc", knc);
   console.log("AGQ kiin", kiin);
   console.log("AGQpostparse knc", knc);
   console.log("AGQpostparse kiin", kiin);
   const { loading, error, data } = useQuery(GET_ALTS, {variables: {keiin: kiin, kenc: knc}});
   if (loading) return <p>Loading...</p>;
   if (error) return <p>Error</p>;
   if (data.alts.length===0) return <p>No data!</p>;
   console.log('returned data', data);
   return (
      <table className='rn-table'>
      <thead>
      <tr>
      <th><abbr title='NATO Item Identication Number'>NIIN</abbr></th>
      <th><abbr title='Alternative Item Relationship Codes'>ARC</abbr></th>
      </tr>
      </thead>
      <tbody>
      {data.alts.map(alts => 
         <tr key={alts.id}>
         <td><Link to={`/itemrecord/${alts.lnc}/${alts.liin}`}>{alts.lnc}{alts.liin}</Link></td>
         <td>{humanreadARC(alts.keylis)}</td>
         </tr>
      )}
      </tbody>
      </table>
   );
}

export default AltResultTable;

