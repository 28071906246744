import React from 'react';
import  { Button } from '@royalnavy/react-component-library';

const SearchBack = ({ returnpath }) => (
   <form action={returnpath}>
      <p>
         <Button type="submit" variant="primary">Search again</Button>
      </p>
   </form>
);

export default SearchBack;

