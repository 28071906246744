import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import GET_ITEM_SUMMARY from 'Queries/GET_ITEM_SUMMARY';
import GET_ITEMS_SUMMARY_ADV from 'Queries/GET_ITEMS_SUMMARY_ADV';

function selectQuickAdvQuery(quickSearchType) {
   if (quickSearchType === true) {
      return GET_ITEM_SUMMARY;
   } else {
      return GET_ITEMS_SUMMARY_ADV;
   }
}


function ItemResultTable(props) {

   function selectQuickAdvVariables(quickSearchType) {
      if (quickSearchType === true) {
         return {variables: {quicksearch: props.quickSearch}};
      } else {
         return {variables: {mpn: props.advMPN, sin: props.advSIN, desc: props.advDesc}};
   
      }
   }

   const { loading, error, data } = 
      useQuery(selectQuickAdvQuery(props.quickSearchType), selectQuickAdvVariables(props.quickSearchType));
   if (loading) return <p>Loading...</p>;
   if (error) return <p>Error</p>;
   if (data.items.length===0) return <p>No data!</p>;
   //console.log('returned data', data);
   //console.log('DMC', data.items["0"].commoditymgr.dmc,);
   return (
      <table className='rn-table'>
      <thead>
      <tr>
      <th><abbr title='Inventory Management Code'>IMC</abbr></th>
      <th><abbr title='NATO Supply Class'>NSC</abbr></th>
      <th><abbr title='NATO Item Identication Number'>NIIN</abbr></th>
      <th>Short Item Name</th>
      <th>Manafacturer's<br/> Part No</th>
      </tr>
      </thead>
      <tbody>
      {data.items.map(items => 
         <tr key={items.id}>
         <td>{items.commoditymgr.dmc}</td>
         <td>{items.nsc}</td>
         <td><Link to={`itemrecord/${items.nc}/${items.iin}`}>{items.nc}{items.iin}</Link></td>
         <td><Link to={`itemrecord/${items.nc}/${items.iin}`}>{items.sin}</Link></td>
         <td>{items.crisppartno}</td>
         </tr>
      )}
      </tbody>
      </table>
   );
}



export default ItemResultTable;

